import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Link } from "gatsby";
import breakpoint from "utils/breakpoints/";
import { colors } from "utils/variables/";

// Components
import Container from "components/container";

// Icons
import FullLogo from "assets/icons/full-logo.inline.svg";
import Instagram from "assets/icons/icon-instagram.inline.svg";
import LinkedIn from "assets/icons/icon-linkedin.inline.svg";
import Dribbble from "assets/icons/icon-dribbble.inline.svg";
import Medium from "assets/icons/icon-medium.inline.svg";

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMenu = styled.nav`
  max-width: 100vw;
  width: 100%;
  position: fixed;
  top: ${(props) => (props.isMounted ? "0" : "-64px")};
  right: 0;
  left: 0;
  padding: 24px 0;
  background-color: ${colors.green};
  opacity: ${(props) => (props.isMounted ? "1" : "0")};
  transition: all 0.6s;
  z-index: 9000;

  ${(props) =>
    !props.hasScrolled
      ? css`
          background-color: transparent;
        `
      : null}

  ${breakpoint.medium`
    padding: 64px 0;

    ${(props) =>
      props.hasScrolled
        ? css`
            padding: 24px 0;
          `
        : null}
  `}

  ${(props) =>
    props.isScrollingDown
      ? css`
          top: -160px;
        `
      : null}

  .menu__logo {
    position: relative;
    display: flex;
    z-index: 9001;

    svg {

      * {
        fill: ${colors.stout};

        ${(props) =>
          props.open
            ? css`
                fill: ${colors.white};
              `
            : ``}
      }
    }
  }

  .menu__toggler {
    position: relative;
    z-index: 9001;

    ${breakpoint.medium`
      display: none;
    `}

    span {
      width: 22px;
      height: 3px;
      position: relative;
      display: block;
      margin-bottom: 4px;
      background-color: ${colors.stout};
      border-radius: 16px;
      transition: all 0.3s ease;

      &:last-child {
        margin: 0;
      }

      ${(props) =>
        props.open
          ? css`
              background-color: ${colors.white};
            `
          : ``}
    }

    .top {
      top: ${(props) => (props.open ? "7px" : "0")};
      transform: ${(props) => (props.open ? "rotate(45deg)" : "")};
    }

    .middle {
      opacity: ${(props) => (props.open ? "0" : "1")};
      visibility: ${(props) => (props.open ? "hidden" : "visible")};
    }

    .bottom {
      top: ${(props) => (props.open ? "-7px" : "0")};
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "")};
    }
  }

  .menu__content {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 24px;
    background-color: ${colors.midnight};
    opacity: ${(props) => (props.open ? "1" : "0")};
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    transition: all 0.3s;

    ${breakpoint.medium`
      width: auto;
      height: auto;
      position: relative;
      padding: 0;
      background-color: transparent;
      opacity: 1;
      visibility: visible;
    `}

    ul {
      padding: 0;
      margin-top: -32px;

      ${breakpoint.medium`
        display: flex;
        align-items: center;
        margin: 0;
      `}
    }

    li {
      margin-bottom: 32px;

      &:last-child {
        margin: 0;
      }

      ${breakpoint.medium`
        margin: 0 32px 0 0;
      `}

      a {
        color: ${colors.white};
        font-size: 32px;
        line-height: 1.2em;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease;

        &:hover,
        &.active {
          border-color: ${colors.supernova};
        }

        ${breakpoint.medium`
          position: relative;
          display: inline-block;
          color: ${colors.stout};
          font-size: 20px;
          font-weight: 500;
          line-height: 1.2em;
          z-index: 200;

          &:hover,
          &.active {
            border-color: ${colors.stout};
          }
        `}
      }
    }

    .menu__social-links {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 64px;

      ${breakpoint.medium`
        display: none;
      `}

      a {
        display: flex;
        margin-right: 42px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: 22px;
          height: auto;
        }
      }
    }
  }
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMounted: false,
      isMenuOpen: false,
      hasScrolled: false,
      isScrollingDown: false,
      lastScrollPosition: 0,
      interval: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);

    if (!this.state.hasMounted) {
      this.setState({
        hasMounted: true,
      });
    }

    this.handleScroll();

    const interval = setInterval(() => {
      if (
        !this.state.isScrollingDown &&
        !this.state.isMenuOpen &&
        this.state.hasScrolled
      ) {
        this.setState({
          isScrollingDown: true,
        });
      }
    }, 4000);

    this.setState({
      interval: interval,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
    this.setState({
      interval: null,
    });
  };

  handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 16) {
      if (!this.state.hasScrolled) {
        this.setState({
          hasScrolled: true,
        });
      }
    } else {
      if (this.state.hasScrolled) {
        this.setState({
          hasScrolled: false,
        });
      }
    }

    if (
      scrollPosition > this.state.lastScrollPosition &&
      this.state.hasScrolled
    ) {
      if (!this.state.isScrollingDown) {
        this.setState({
          isScrollingDown: true,
        });
      }
    } else {
      if (this.state.isScrollingDown) {
        this.setState({
          isScrollingDown: false,
        });
      }
    }

    this.setState({
      lastScrollPosition: scrollPosition,
    });
  };

  closeMenu = () => {
    if (this.state.isMenuOpen) {
      this.setState(
        {
          isMenuOpen: false,
        },
        () => {
          document.querySelector("html").classList.remove("no-scroll");
          document.querySelector("body").classList.remove("no-scroll");
        }
      );
    }
  };

  toggleMenu = () => {
    this.setState(
      {
        isMenuOpen: !this.state.isMenuOpen,
      },
      () => {
        if (this.state.isMenuOpen) {
          document.querySelector("html").classList.add("no-scroll");
          document.querySelector("body").classList.add("no-scroll");
        } else {
          document.querySelector("html").classList.remove("no-scroll");
          document.querySelector("body").classList.remove("no-scroll");
        }
      }
    );
  };

  render = (props) => (
    <StyledMenu
      isMounted={this.state.hasMounted}
      backgroundColor={this.props.backgroundColor}
      hasScrolled={this.state.hasScrolled}
      isScrollingDown={this.state.isScrollingDown}
      open={this.state.isMenuOpen}
    >
      <StyledContainer>
        <a
          aria-label="Logo"
          className="menu__logo"
          href="https://indicius.com/"
        >
          <FullLogo />
        </a>

        <button
          className="menu__toggler"
          type="button"
          onClick={this.toggleMenu}
        >
          <span className="top"></span>
          <span className="middle"></span>
          <span className="bottom"></span>
        </button>

        <div className="menu__content">
          <div>
            <ul>
              <li>
                <Link
                  onClick={this.closeMenu}
                  to="#alt-humus"
                  partiallyActive={true}
                >
                  Ganador
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.closeMenu}
                  to="#requisitos"
                  partiallyActive={true}
                >
                  Premio
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.closeMenu}
                  to="#participantes"
                  partiallyActive={true}
                >
                  Participantes
                </Link>
              </li>
              <li>
                <a
                  onClick={this.closeMenu}
                  href="https://drive.google.com/file/d/11B7GcPH4fDE2WbVnZ7mYoWKHuVrOYgeP/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bases y condiciones
                </a>
              </li>
            </ul>

            <div className="menu__social-links">
              <a
                aria-label="Instagram"
                href="https://www.instagram.com/indiciusar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
              <a
                aria-label="LinkedIn"
                href="https://www.linkedin.com/company/indicius/?originalSubdomain=ar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn />
              </a>
              <a
                aria-label="Dribbble"
                href="https://dribbble.com/Indicius"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Dribbble />
              </a>
              <a
                aria-label="Medium"
                href="https://medium.com/@Indicius"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Medium />
              </a>
            </div>
          </div>
        </div>
      </StyledContainer>
    </StyledMenu>
  );
}

export default Menu;
