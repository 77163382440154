// Breakpoints:start
export const breakpoints = {
  small: '768',
  medium: '1024',
  large: '1200',
  extraLarge: '1440',
  wide: '2000'
}
// Breakpoints:end

// Colors:start
export const colors = {
  // Insert your variables here
  black: '#000',
  indiblue: '#3098F3',
  midnight: '#082C4E',
  supernova: '#FFCC00',
  watermelon: '#FE6571',
  cream: '#F2EAE2',
  white: '#FFFFFF',
  cloudy: '#D9D9DA',
  stone: '#747481',
  stout: '#05051E',
  successGreen: '#0be881',
  errorRed: '#ff3f34',
  dangerYellow: '#ffd32a',
  green: '#3D9951'
}
// Colors:end

// Fonts:start
export const fonts = {
  primary: 'Archivo',
  secondary: 'DM Sans',
  userTest: 'Kalam'
}
// Fonts:end

// Font Weights
export const weights = {
  400: '400',
  500: '500',
  600: '600',
  700: '700'
}
