import React from "react";

// Libraries
import Helmet from "react-helmet";

// Styles
import "assets/styles/reset.css";
import GlobalStyles from "assets/styles/globalStyles";

// Components
import SEO from "components/seo/";
import Menu from "components/menu/";
import Footer from "components/footer/";

const Layout = ({ children }) => (
  <main>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css"
      />
    </Helmet>
    <SEO />
    <GlobalStyles />
    <Menu />
    {children}
    <Footer />
  </main>
);

export default Layout;
