import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Link } from "gatsby";
import { colors } from "utils/variables/";

const ButtonStyles = css`
  position: relative;
  display: inline-block;
  padding: 16px 32px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => (props.color ? props.color : colors.white)};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.6s;
  z-index: 1000;
  overflow: hidden;

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor ? props.hoverBackgroundColor : ""};
    color: ${(props) => (props.hoverColor ? props.hoverColor : "")};

    &::after {
      transform: translateX(100%);
      opacity: 1;
    }
  }
`;

const StyledButtonLink = styled(Link)`
  ${ButtonStyles}
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

const ButtonLink = (props) => (
  <StyledButtonLink {...props}>{props.text}</StyledButtonLink>
);

export default ButtonLink;
