import React from "react";

// Libraries
import styled, { keyframes, css } from "styled-components";

// Utils
import breakpoint from "utils/breakpoints/";
import { colors } from "utils/variables/";

// Components
import Form from "components/forms/";

// Icons
import Arrow from "assets/icons/icon-arrow-newsletter.inline.svg";
import Check from "assets/icons/icon-check.inline.svg";
import Error from "assets/icons/icon-input-error.inline.svg";

const dotCollisionBefore = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
`;

const dotCollisionAfter = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
`;

const StyledForm = styled(Form)`
  max-width: 352px;
  width: 100%;

  ${breakpoint.small`
    width: 352px;
  `}

  .form__group {
    position: relative;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  input {
    width: 100%;
    padding-right: 60px;
    color: ${(props) => (props.dark ? colors.midnight : colors.white)};
    border-color: ${(props) =>
      props.isLoading || props.showSuccess ? colors.supernova : colors.cloudy};

    ${(props) =>
      props.dark
        ? css`
            border-color: ${(props) =>
              props.isLoading || props.showSuccess
                ? colors.indiblue
                : colors.midnight};
          `
        : ``}

    ${(props) =>
      props.showError
        ? css`
            border-color: ${colors.watermelon}!important;
          `
        : ``}

    &:focus {
      border-color: ${(props) =>
        props.dark ? colors.indiblue : colors.supernova};
    }

    &::placeholder {
      color: ${(props) => (props.dark ? colors.midnight : colors.cloudy)};
    }
  }

  button[type="submit"] {
    width: 41px;
    height: 41px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 16px 0;
    cursor: pointer;

    .dots {
      position: relative;
      display: ${(props) => (props.isLoading ? "block" : "none")};
      width: 10px;
      height: 10px;
      margin: 0 auto;
      border-radius: 5px;
      background-color: ${colors.white};
      color: ${colors.white};
    }

    .dots::before,
    .dots::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dots::before {
      left: -10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: ${colors.white};
      color: ${colors.white};
      animation: ${dotCollisionBefore} 1s infinite ease-in;
    }

    .dots::after {
      left: 10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: ${colors.white};
      color: ${colors.white};
      animation: ${dotCollisionAfter} 1s infinite ease-in;
      animation-delay: 0.5s;
    }

    .arrow {
      display: ${(props) =>
        props.showSuccess || props.showError || props.isLoading
          ? "none"
          : "block"};
      margin-left: auto;

      * {
        fill: ${(props) => (props.dark ? colors.midnight : colors.white)};
      }
    }

    .check {
      display: ${(props) => (props.showSuccess ? "block" : "none")};
      margin-left: auto;

      * {
        stroke: ${(props) => (props.dark ? colors.indiblue : colors.supernova)};
      }
    }

    .error {
      display: ${(props) => (props.showError ? "block" : "none")};
      margin-left: auto;
    }
  }

  .message {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    display: none;
    font-size: 16px;

    &.message--success {
      display: ${(props) => (props.showSuccess ? "block" : "none")};
      color: ${(props) => (props.dark ? colors.indiblue : colors.supernova)};
    }

    &.message--error {
      display: ${(props) => (props.showError ? "block" : "none")};
      color: ${colors.watermelon};
    }
  }
`;

class NewsletterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoading: false,
      showSuccess: false,
      showError: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.testEmail(this.state.email)) {
      // Shows loader
      this.setState({
        showError: false,
        isLoading: true,
      });

      // Data
      const data = {
        fields: [
          {
            name: "email",
            value: this.state.email,
          },
          {
            name: "lifecyclestage",
            value: "subscriber",
          },
        ],
        context: {
          pageUri: window.location.href,
        },
      };

      // Post request
      fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/2094487/84c23991-fa1c-4c37-87ea-e1ed14d3f498",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": data.length,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.setState({
              isLoading: false,
              showError: false,
              showSuccess: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!this.state.showError) {
        this.setState({
          showError: true,
        });
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
    });

    // Remove error state if it's visible
    if (this.state.showError) {
      this.setState({
        showError: false,
      });
    }
  };

  testEmail = (email) => {
    const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
    return emailRegex.test(email);
  };

  render = (props) => (
    <StyledForm
      dark={this.props.dark}
      onSubmit={this.handleSubmit}
      isLoading={this.state.isLoading}
      showError={this.state.showError && this.state.email !== ""}
      showSuccess={this.state.showSuccess}
      noValidate
    >
      <div className="form__group">
        <label htmlFor="email" aria-label="Email">
          Indi updates. Every month. No spam.
        </label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Subscribe to our newsletter"
          onChange={this.handleInputChange}
        />
        <button
          type="submit"
          aria-label="Submit"
          disabled={this.state.showError}
        >
          <div className="dots"></div>
          <Arrow className="arrow" />
          <Check className="check" />
          <Error className="error" />
        </button>
        <small className="message message--success">
          Yay! You're subscribed!
        </small>
        <small className="message message--error">
          Oopsie! This email is not valid.
        </small>
      </div>
    </StyledForm>
  );
}

export default NewsletterForm;
