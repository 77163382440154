import { createGlobalStyle } from 'styled-components'

// Helpers
import { Helpers } from './helpers'

// Utils
import { fonts } from 'utils/variables/'
import breakpoint from 'utils/breakpoints/'

// Fonts
import DMSans400 from 'assets/fonts/DMSans-Regular.ttf'
import DMSans500 from 'assets/fonts/DMSans-Medium.ttf'
import DMSans700 from 'assets/fonts/DMSans-Bold.ttf'
import Archivo400 from 'assets/fonts/Archivo-Regular.ttf'
import Archivo500 from 'assets/fonts/Archivo-Medium.ttf'
import Archivo700 from 'assets/fonts/Archivo-Bold.ttf'

const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: "DM Sans";
    font-weight: 400;
    src: url("${DMSans400}");
  }
  @font-face {
    font-family: "DM Sans";
    font-weight: 500;
    src: url("${DMSans500}");
  }
  @font-face {
    font-family: "DM Sans";
    font-weight: 700;
    src: url("${DMSans700}");
  }
  @font-face {
    font-family: "Archivo";
    font-weight: 400;
    src: url("${Archivo400}");
  }
  @font-face {
    font-family: "Archivo";
    font-weight: 500;
    src: url("${Archivo500}");
  }
  @font-face {
    font-family: "Archivo";
    font-weight: 700;
    src: url("${Archivo700}");
  }

  body {
    font-family: ${fonts.secondary}, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: 1.4em;
    overflow-x: hidden;

    ${breakpoint.medium`
      font-size: 24px;
      line-height: 1.4em;
    `}

    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      font-weight: 400;
      line-height: 1.2em;
    }

    h1,
    h2 {
      font-family: ${fonts.secondary}, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    }

    h1 {
      font-size: 40px;
      letter-spacing: -.02em;

      ${breakpoint.medium`
        font-size: 56px;
      `}
    }

    h2 {
      font-size: 34px;
      letter-spacing: -.01em;

      ${breakpoint.medium`
        font-size: 42px;
      `}
    }
    
    h3 {
      font-size: 24px;

      ${breakpoint.medium`
        font-size: 32px;
      `}
    }

    h4 {
      font-size: 16px;
      letter-spacing: .01em;
    }

    .gatsby-image-wrapper {
      
      * {
        background-color: transparent!important;
      }
    }

    img,
    svg {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s ease;
    }

    p {
      margin: 0;

      &.paragraph--small {
        font-size: 18px;
        line-height: 26px;
      }
    }

    button {
      padding: 0;
      margin: 0;
      background: none;
      font-family: inherit;
      font-size: inherit;
      border: 0;
      outline: 0;
    }

    .strikethrough {
      width: auto;
      position: relative;
      transition: all .3s;

      &::before {
        content: '';
        width: 0;
        height: 60%;
        position: absolute;
        bottom: 4px;
        left: -8px;
        border-radius: 8px;
        transform: rotate(-1deg);
        transition: all .3s;
        z-index: -1;
      }

      &:hover {

        &::before {
          width: calc(100% + 16px);
        }
      }
    }
  }

  ${Helpers}
`

export default GlobalStyles
